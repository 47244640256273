<!-- 个人中心 -->
<template>
  <div class="person">
    <Spin size="large" fix v-if="spinShow"></Spin>
    <header>
      <Row>
        <Col flex="100px">
        <div>
          <img :src="firm_data.logo?firm_data.logo:errorImg01" style="width:80px;height:80px" alt="">
        </div>
        </Col>
        <Col flex="auto" class="auto">
        <div>
          <p>姓名：{{personage.name}}</p>
          <p>手机号：{{personage.mobile}}</p>
        </div>
        </Col>
        <Col flex="100px">
        <Button type="warning" @click="out">退出账号</Button>
        </Col>
      </Row>
    </header>

    <section>
      <Tabs value="1">
        <!-- left -->
        <TabPane label="基础信息" name="1" style="padding:20px 0 0 0">

          <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">

            <FormItem label="姓名" prop="name">
              <Input v-model="formValidate.name" style="width:500px"></Input>
            </FormItem>

            <FormItem label="部门" prop="department">
              <Input v-model="formValidate.department" style="width:500px"></Input>
            </FormItem>

            <FormItem label="职务" prop="position">
              <Input v-model="formValidate.position" style="width:500px"></Input>
            </FormItem>

            <FormItem label="邮箱" prop="email">
              <Input v-model="formValidate.email" style="width:500px"></Input>
            </FormItem>

            <FormItem>
              <Button type="primary" @click="handleSubmit('formValidate')">保存</Button>
              <Button @click="()=>{this.get_data()}" style="margin-left: 8px">重置</Button>
            </FormItem>

          </Form>

        </TabPane>

        <!-- right -->
        <TabPane label="安全设置" name="2" style="padding:15px 0 0 30px">
          <div>
            <p>账号：{{firm_mobile}}</p>
          </div>
          <div style="margin:30px 0">
            <p>
              手机号：{{personage.mobile}}
              <span style="color:#165DFF;margin-left:20px;cursor: pointer;" @click="$router.push('changePhone')">更换手机号</span>
            </p>
          </div>
          <div>
            <p>
              密码：
              <span style="color:#165DFF;margin-left:20px;cursor: pointer;" @click="$router.push('changePassword')">修改密码</span>
            </p>

          </div>
        </TabPane>

      </Tabs>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firm_mobile: localStorage.getItem('firm_mobile'),
      spinShow: false,
      personage: {},
      formValidate: {

      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        department: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ],
        position: [
          { required: true, message: '请输入职务', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入职务邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确职务格式', trigger: 'blur' }
        ],
      }
    }
  },
  props: ['firm_data'],
  methods: {
    //表单提交
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.spinShow = true
          this.app('enterpriseOperators', 'updateEmployee', {
            name: this.formValidate.name,
            department: this.formValidate.department,
            position: this.formValidate.position,
            email: this.formValidate.email,
            _id: this.formValidate._id,
            mobile: localStorage.getItem('firm_mobile'),
          }).then(res => {
            console.log(res);
            if (res.code == 200) {
              this.get_data()
            } else {
              this.$Message.error('修改失败');
              this.spinShow = false
            }
          })
        } else {
          this.$Message.warning('请填写完整信息');
        }
      })
    },
    //退出登录
    out() {
      localStorage.removeItem("firm_mobile")
      localStorage.removeItem("firm_token")
      localStorage.removeItem("firm_enterpriseId")
      this.$router.push('/login')
    },
    //封装获取数据
    get_data() {
      this.spinShow = true
      this.app('enterpriseOperators', 'getEmployeeMassage', {
        mobile: localStorage.getItem('firm_mobile')
      }).then(res => {
        console.log(res);
        this.personage = res.data
        this.formValidate = res.data
        this.spinShow = false
      })

    }
  },
  created() {
    this.get_data()
  }
}
</script>

<style lang="less" scoped>
@import "./person.less";
</style>
